<template>
  <div class="wrap">
    <img v-show="showTxt"
         src="@/assets/guide/guide_download.webp"
         alt="">
    <div v-show="!showTxt">
      <img src="@/assets/guide/download.png"
           alt="">
      <el-button class="btn"
                 type="primary"
                 @click="downloadApp">
        下载APP</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showTxt: false,
      // showTxt: true,
      src: "https://sunraytiku.oss-cn-beijing.aliyuncs.com/appDownloadAddress/ttjb.apk"
    }
  },
  methods: {
    getAppInfo () {
      if (this.isWeixin()) {
        this.showTxt = true
      } else {
        this.showTxt = false
      }
    },
    isWeixin () {
      var WxObj = window.navigator.userAgent.toLowerCase();
      if (WxObj.match(/microMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    downloadApp () {
      window.open(this.src, '_self')
    }
  },
  mounted () {
    this.getAppInfo()
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.btn {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50%;
  min-width: 90px;
  font-size: 14px;
  border-color: #2196f3;
  background: #2196f3;
}
// .el-button--primary {
//     color: #FFF;
//     background-color: #409EFF;
//     border-color: #409EFF;
//     background: #2196F3;
// }
.wrap {
  position: relative;
}

@media only screen and (min-width: 600px) {
  .btn {
    padding: 16px 0;
    font-size: 28px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  .btn {
    padding: 10px 0;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 479px) {
  .btn {
    padding: 6px 0;
    font-size: 20px;
    line-height: 28px;
  }
}
</style>